import Experiments from '@wix/wix-experiments'

export const fetchExperiments = async (): Promise<Experiments> => {
  const experiments = new Experiments({
    scope: 'wix-events-ui',
  })

  await experiments.ready()
  return experiments
}

export const installPageEnabled = (experiments: Experiments) => experiments.enabled('specs.events.ui.InstallPage')
export const concurrentEditingEnabled = (experiments: Experiments) =>
  experiments.enabled('specs.events.ui.ConcurrentEditing')
